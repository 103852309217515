
  import { mapActions } from 'vuex'

  import AppAlert from '@loavies/component-library/dist/app-alert'

  import {
    REGISTER,
    MY_ACCOUNT,
    FORGOT_PASSWORD,
  } from '~/lib/constants/routes'

  export default {
    name: 'LoginPage',
    components: {
      AppAlert,
    },
    data() {
      return {
        loading: false,
        authError: null,
      }
    },
    head() {
      return {
        title: this.$pageHelperService.getFormattedPageTitle(this.$t('login')),
      }
    },
    methods: {
      ...mapActions({
        login: 'user/login',
        showToastMessage: 'toast/showToastMessage',
      }),
      handleRegister() {
        return this.$router.push(this.localePath(REGISTER))
      },
      handleForgotPassword() {
        return this.$router.push(this.localePath(FORGOT_PASSWORD))
      },
      async handleLogin(loginUserModel) {
        try {
          this.loading = true

          await this.login(loginUserModel)

          return this.$router.push(this.localePath(MY_ACCOUNT))
        } catch (error) {
          if (error.response?.status === 401) {
            return this.authError = this.$t('login_failed')
          }

          return this.showToastMessage({
            text: this.$t('general_error'),
            icon: 'IconClose',
          })
        } finally {
          this.loading = false
        }
      },
    },
  }
