
  import { LoginUserModel } from '@loavies/c2c-models'

  import AppButton from '@loavies/component-library/dist/app-button'
  import EmailInput from '@loavies/component-library/dist/email-input'
  import PasswordInput from '@loavies/component-library/dist/password-input'
  import AppSpinner from '@loavies/component-library/dist/app-spinner'

  import FormValidationBase from '~/mixins/form-validation-base'

  export default {
    components: {
      AppButton,
      PasswordInput,
      EmailInput,
      AppSpinner,
    },
    mixins: [FormValidationBase],
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
    },
    emits: [
      'forgot-password',
      'register-user',
      'submit',
    ],
    data() {
      return {
        email: '',
        password: '',
      }
    },
    methods: {
      submitForm() {
        if (!this.isFormValid || this.loading) {
          return
        }

        const loginUserModel = new LoginUserModel({
          username: this.email,
          password: this.password,
        })

        this.$emit('submit', loginUserModel)
      },
    },
  }
