import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
  },
  data() {
    return {
      isFormValid: false,
      isFormLoading: false,
      formErrorMessage: '',
    }
  },
  methods: {
    /**
     * @param {string} refName
     * @returns {void}
     */
    async checkFormValidity(refName) {
      this.isFormValid = await this.$refs[refName]?.validate({ silent: true })
    },
  },
}
